<template>
  <div>
    <div class="switch">
      <span class="leftName">{{ userName }}</span>
      <span class="phone">
        <img src="../assets/img/switching.png" alt="" @click="goBack"
      /></span>
    </div>
    <div class="loginType">
      <div class="right" @click="nextStep"><i>ENTER</i></div>
    </div>
    <div class="clickViews">
      <van-field name="checkboxGroup" label="">
        <template #input>
          <van-checkbox v-model="checked" icon-size="14px" class="checkbox">
          </van-checkbox>
          <p class="checktitle">
            <i class="iBox" @click="protocolPrivacy"
              >views terms and agreements</i
            >
          </p>
        </template>
      </van-field>
    </div>
  </div>
</template>

<script>
import "../assets/css/unified.css";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { reactive, toRefs, onMounted } from "@vue/runtime-core";
import urlencode from "urlencode";
export default {
  name: "loginFrist",
  props: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      from: [],
      checked: "",
      userName: "",
    });
    // 协议指南
    const protocolPrivacy = () => {
      router.push({ path: "/agreement" });
    };
    // 切换用户
    const goBack = () => {
      sessionStorage.setItem("switch", "true");
      router.push({ path: "/login" });
    }; // 下一步
    const nextStep = () => {
      if (state.checked == null || state.checked == "") {
        Toast.fail("You need to read and agree to our Terms before logging in");
        return false;
      }
      Toast.success("Login succeeded");
      // window.ue4("saveUser", {
      //   token: sessionStorage.getItem("token"),
      // });
      const saveData = ["saveUser"];
      saveData.push({
        token: sessionStorage.getItem("token"),
      });
      const param = urlencode(JSON.stringify(saveData));
      location.href = `${process.env.VUE_APP_BASE_URL}end.html?` + param;
      // 进入游戏
    };
    onMounted(() => {});
    return {
      ...toRefs(state),
      goBack,
      nextStep,
      protocolPrivacy,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0px;
  padding: 0px;
  // 切换用户
  .switch {
    position: absolute;
    top: 29px;
    right: 27px;
    height: 26px;
    line-height: 26px;
    display: flex;
    .leftName {
      color: #ffffff;
      margin-right: 10px;
    }
    .phone {
      width: 120px !important;
      img {
        width: 100%;
      }
    }
  }
  .loginType {
    // width: 30%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 40px;
    left: 40%;
    color: #ffffff;
    .right {
      width: 136px;
      height: 26px;
      line-height: 26px;
      background-image: url("../assets/img/account.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  // 选择框
  .clickViews {
    position: absolute;
    width: 60%;
    height: auto;
    left: 32%;
    bottom: 12.75px;
    overflow: hidden;
    .van-cell {
      background-color: transparent;
    }
    .iBox {
      color: gray;
      font-size: 14px;
      margin-left: 10px;
    }
  }
}
</style>
